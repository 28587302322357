import React, { useState } from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import { RadioGroup } from "@headlessui/react";
import { ArrowRight, CheckCircle2Icon, CheckCircleIcon, CheckIcon, Info, Pencil } from "lucide-react";
import Button from "../components/atoms/Button";
import { prices, estimateDetail, estimateMultiplier } from "../data/costEstimate";
  

export default function CareersPage(props) {
   
  return (
    <Layout pageProps={props}>
      <SEO
        title={"Careers at Roach Home Improvement"}
        description={"Roach Home Improvement is always looking for talented individuals to join our team. We offer competitive pay, benefits, and a great work environment."}
      />{" "}
      
       
 
          <div className="bg-neutral-100 py-20">
        <div className="px-6 md:px-8 max-w-7xl mx-auto pb-20">
       
          <div className=" my-8 max-w-5xl mx-auto bg-white rounded-2xl shadow-xl -mt-10 border border-neutral-900/5">
          
            <div className="p-8">
              <h1 className="text-4xl font-bold text-neutral-900">Careers at Roach Home Improvement</h1>
              <p className="text-lg text-neutral-700 mt-2">
                Roach Home Improvement is always looking for talented individuals to join our team. We offer competitive pay, benefits, and a great work environment.
              </p>
              <div className="mt-8 border-l-4 border-brand-600 p-8 bg-brand-50 rounded-xl">
                There are currently no open positions. Please check back later.
              </div>
            </div>
             
          </div>
        </div>
        </div>
 
    </Layout>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
